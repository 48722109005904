import React, {HTMLProps, useEffect, useMemo, useRef, useState} from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { changeEstimate, setCurrentDetails, setTableState } from "../../../store/slices/estimates";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import { Center, Icon, Stack, Text } from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { ActTable } from "../../lib/table/ActTable";
import { IDetail, IDetailField } from "../../../api/estimates/types";
import styles from "../../lib/input/input.module.scss";
import { Row } from "@tanstack/react-table";
import { formatWorkActNumbers } from "../../../helpers/formatWorkActNumbers";
import { formatAmount } from "../../../helpers/formatAmount";

interface MainTableProps {
  searchValue: string;
}

function changeDetail(newDetail: IDetail, updatedDetails: IDetail[]): IDetail[] {
  const updatedDetailsCopy = [...updatedDetails];

  if (!newDetail.parent_id) {
    return updatedDetailsCopy.map(detail =>
      (detail.id === newDetail.id) ? newDetail : detail
    );
  }
  function updateDetailRecursive(details: IDetail[]): IDetail[] {
    return details.map(detail => {
      if (detail.id === newDetail.id) {
        return newDetail;
      } else if (detail.subRows) {
        detail.subRows = updateDetailRecursive(detail.subRows);
      }
      return detail;
    });
  }

  return updateDetailRecursive(updatedDetailsCopy);
}

function plusValueForParentsAndLeaf(row: Row<IDetail>, value: number, updatedDetails: IDetail[], newQuantity: string): IDetail[] {
  const newAmount = Number(Number(row.original.Theamount) + value);
  const newPercentage = (1 - ((Number(row.original.AmountRemaining) - newAmount) / Number(row.original.AmountEstimate))) * 100;
  const updatedDetail = { ...row.original, quantityinthedocument: Number(newQuantity).toFixed(3), Theamount: newAmount.toFixed(3), Total: newAmount.toFixed(3), PercentageOfCompletion: newPercentage.toFixed(2) };
  updatedDetails = changeDetail(updatedDetail, updatedDetails);

  let parentRow = row.getParentRow();
  while (parentRow) {
    updateParentRecursivelyDeep(parentRow.original, updatedDetails, value);
    parentRow = parentRow.getParentRow();
  }

  return updatedDetails;
}
function updateParentRecursivelyDeep(parent:any, updatedDetails:any, value:any, currentLevel = updatedDetails) {
  for (let i = 0; i < currentLevel.length; i++) {
    const el = currentLevel[i];
    if (el.id === parent.id) {
      currentLevel[i] = {
        ...currentLevel[i],
        Theamount: (Number(currentLevel[i].Theamount) + value).toFixed(2),
        Total: (Number(currentLevel[i].Theamount) + value).toFixed(2)
      };
    }
    if (el.subRows) {
      updateParentRecursivelyDeep(parent, el.subRows, value, el.subRows); // Рекурсивный вызов для subRows
    }
  }
}

const ExpandCell = (info: CellContext<IDetail, string>) => {
  const { currentEstimate } = useAppSelector((state) => state.estimates);
  const dispatch = useAppDispatch();
  const { row, table } = info;
  const checkedRows = table.getSelectedRowModel().flatRows;

  useEffect(() => {
    const details = checkedRows.map((row) => row.original);
    const filteredDetails = details.filter((el) => !(el.subRows && el.subRows?.length > 0))
    dispatch(setCurrentDetails(filteredDetails));
    dispatch(setTableState(table.getState()));
  }, [checkedRows, dispatch, currentEstimate?.details]);

  function findAllLeafs(row: Row<IDetail>, updatedDetails: IDetail[]){
    if(!row.getCanExpand()){
      const value = row.getIsSelected() ? (0 - Number(row.original.quantityinthedocument)) * Number(row.original.Price) : Number(row.original.Thenumberofstops) * Number(row.original.Price)
      const newQuantity = row.getIsSelected() ? "0.00" : row.original.Thenumberofstops
      updatedDetails = plusValueForParentsAndLeaf(row, value, updatedDetails, newQuantity)
    }
    else {
      row.subRows.forEach((el) => {
        if(el.getIsSelected() === row.getIsSelected()){
          updatedDetails = findAllLeafs(el, updatedDetails)
        }
      })
    }

    return updatedDetails

  }

  function checkboxClick(row: Row<IDetail>){

    var updatedDetails: IDetail[] = JSON.parse(JSON.stringify(currentEstimate?.details))
    updatedDetails = findAllLeafs(row, updatedDetails)
    const updatedEstimate = {...currentEstimate, details: updatedDetails};
    dispatch(changeEstimate(updatedEstimate));
    row.toggleSelected(!row.getIsSelected())

  }

  useEffect(() => {
    if (Number(row.original.Theamount) > 0) {
      if (row.getCanExpand()) {
        if (!row.getIsExpanded()) {
          row.getToggleExpandedHandler()()
        }
      } else {
        row.toggleSelected(true)
      }
    }
  }, [row.original.Theamount]);
  return (
    <Stack spacing={5} direction="row" align="center">
      <IndeterminateCheckbox
        {...{
          disabled:( !Number(row.getValue("Thenumberofstops")) && !row.getCanExpand()) || row.original.execution_disabled,
          checked: row.getIsSelected(),
          indeterminate: row.getIsSomeSelected(),
          onClick: () => checkboxClick(row),
          onChange: row.getToggleSelectedHandler()
        }}
      />
      {row.getCanExpand() && (
        <Icon
          cursor="pointer"
          mr={2}
          fontSize="12px"
          as={row.getIsExpanded() ? MinusIcon : AddIcon}
          onClick={row.getToggleExpandedHandler()}
        />
      )}
    </Stack>
  );
};

const WorkTypeCell = (info: CellContext<IDetail, string>) => (
  <Text>{info.getValue()}</Text>
);

const CenterCell = (text: string) => <Center w="full">{text}</Center>;
const ConstructionObjectHeader = () => <Text>Объект<br />стр-ва</Text>

const QuantityInDocumentCell = (info: CellContext<IDetail, string>) => {
  const {row} = info;
  const dispatch = useAppDispatch();
  const {currentEstimate} = useAppSelector((state) => state.estimates);
  const [value, setValue] = useState(row.original.quantityinthedocument);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    let formatValue = rawValue.replace(/[^0-9.]/g, "").replace(/^0+(?!\.[0-9])/g, "");
    if (Number(formatValue) > Number(row.original.Thenumberofstops)) {
      formatValue = row.original.Thenumberofstops
    }
    if (formatValue === "") {
      formatValue = "0.000"
    } else {
      formatValue = parseFloat(formatValue).toFixed(3)
    }
    setValue(formatValue);
    const diff = (Number(formatValue) - Number(row.original.quantityinthedocument)) * Number(row.original.Price)
    var updatedDetails: IDetail[] = JSON.parse(JSON.stringify(currentEstimate?.details))

    updatedDetails = plusValueForParentsAndLeaf(row, diff, updatedDetails, formatValue)

    if (Number(formatValue) > 0) {
      info.row.toggleSelected(true);
    } else {
      info.row.toggleSelected(false);
    }

    const updatedEstimate = {...currentEstimate, details: updatedDetails};
    dispatch(changeEstimate(updatedEstimate));
    }

  useEffect(() => {
    setValue(String(Number(row.original.quantityinthedocument).toFixed(3)))
  }, [row.original]);

  return !!row.subRows.length ? null : (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={`${styles.input_container} ${styles.table_input}`}>
          <input
            disabled={info.row.original.execution_disabled}
            name={`quantityinthedocument_${row.original.id}`}
            value={value}
            onChange={(e) => setValue(e.target.value.replace(/[^0-9.]/g, ""))}
            onBlur={handleBlur}
            onKeyDown={(e) => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
            style={{
              maxWidth: "90px",
              color: info.row.original.execution_disabled ? "#8089A2" : "#000"
            }}
          />
        </div>
      </div>
    </div>
  );
};

const DocumentAmountCell = (info: CellContext<IDetail, string>) => {
  const [amount, setAmount] = useState("");

  useEffect(() => {
    setAmount(formatAmount(info.row.original.Theamount));
  }, [info.row.original.Theamount]);

  return <Text>{amount}</Text>;
};

const PercentageOfCompletionCell = (info: CellContext<IDetail, string>) => {
  return <Text>{formatAmount(info.row.original.PercentageOfCompletion)}</Text>;
}

const CodifierCell = (info: CellContext<IDetail, string>) => {
  return <Text>{info.renderValue()}</Text>
}

export function MainTable({ searchValue }: MainTableProps) {
  const searchFields: IDetailField[] = useMemo(() => [
    "Codifier", "TypeofWorkName", "UnitOfMeasurementName", "ConstructionObjectName", "Price",
    "Quantity", "AmountEstimate", "Thenumberofstops", "AmountRemaining",
    "quantityinthedocument", "PercentageOfCompletion"
  ], []);

  const { currentEstimate } = useAppSelector((state) => state.estimates);

  const columnHelper = createColumnHelper<IDetail>();
  const dispatch = useAppDispatch()

  const [filteredDetails, setFilteredDetails] = useState([] as IDetail[]);

  useEffect(() => {
    var filtered: IDetail[] = [];
    if (currentEstimate?.details) {
      filtered = [...currentEstimate.details];
    }

    // Сортировка
    filtered?.sort((a, b) => {
      const codifierA = a.Codifier || '';
      const codifierB = b.Codifier || '';
      return codifierA.localeCompare(codifierB, undefined, { numeric: true, sensitivity: 'base' });
    });

    // Глубокое копирование для избежания мутации
    filtered = JSON.parse(JSON.stringify(filtered));

    // Рекурсивная функция фильтрации с учетом родительских элементов
    function filterDetails(details: IDetail[]): IDetail[] {
      return details.filter(detail => {
        // Проверка на соответствие поиску
        const hasSearchValue = searchFields.some((field) =>
          String(detail[field]).toLowerCase().includes(String(searchValue).toLowerCase())
        );

        // Если текущий элемент соответствует поиску, добавляем все его потомки
        if (hasSearchValue) {
          return true;
        } else {
          // Рекурсивно фильтруем subRows
          detail.subRows = detail.subRows?.filter(subRow => filterDetails([subRow])[0]);
          // Возвращаем true, если хотя бы один subRow соответствует поиску
          return !!detail.subRows?.length;
        }
      });
    }

    // Фильтруем filtered с учетом родителей
    if (currentEstimate?.details?.length) {
      filtered = filterDetails(filtered);
      setFilteredDetails(filtered);
    }
  }, [currentEstimate?.details, currentEstimate?.details?.length, searchFields, searchValue]);


  useEffect(() => {
    return () => {
      dispatch(setTableState(null));
    }
  }, [])


  const columns = useMemo<Array<ColumnDef<IDetail, string>>>(
    () => [
      columnHelper.group({
        id: "info",
        columns: [
          {
            id: "column",
            cell: ExpandCell,
          },
          columnHelper.accessor("Codifier", {
            header: "Номер",
            cell: CodifierCell,
          }),
          columnHelper.accessor("TypeofWorkName", {
            header: "Вид работ",
            cell: WorkTypeCell,
          }),
          columnHelper.accessor("UnitOfMeasurementName", {
            header: "Ед.изм.",
          }),
          columnHelper.accessor("ConstructionObjectName", {
            header: ConstructionObjectHeader
          }),
          columnHelper.accessor("Price", {
            header: "Цена",
            cell: (info) => (!info.row.getCanExpand() ? formatAmount(info.getValue()) : "")
          }),
        ],
      }),
      columnHelper.group({
        id: "estimate",
        header: () => CenterCell("Смета"),
        columns: [
          columnHelper.accessor("Quantity", {
            header: "Количество",
            cell: (info) => (!info.row.getCanExpand() ? formatWorkActNumbers(info.getValue()) : "")
          }),
          columnHelper.accessor("AmountEstimate", {
            header: "Сумма",
            cell: (info) => formatAmount(info.getValue())
          }),
        ],
      }),
      columnHelper.group({
        id: "balance",
        header: () => CenterCell("Остаток"),
        columns: [
          columnHelper.accessor("Thenumberofstops", {
            header: "Количество",
            cell: (info) => (!info.row.getCanExpand() ? formatWorkActNumbers(info.getValue()) : "")
          }),
          columnHelper.accessor("AmountRemaining", {
            header: "Сумма",
            cell: (info) => formatAmount(info.getValue())
          }),
        ],
      }),
      columnHelper.group({
        id: "document",
        header: () => CenterCell("В документ"),
        columns: [
          columnHelper.accessor("quantityinthedocument", {
            header: "Количество",
            cell: QuantityInDocumentCell,
            meta: {cellClassName: "quantity-in-document"}
          }),
          columnHelper.accessor("Theamount", {
            header: "Сумма",
            cell: DocumentAmountCell,
          }),
        ],
      }),
      columnHelper.accessor("PercentageOfCompletion", {
        header: "% выполнения",
        cell: PercentageOfCompletionCell,
      }),
    ],
    [columnHelper]
  );


  return (
    <ActTable
      data={filteredDetails}
      columns={columns}
      searchValue={searchValue}
    />
  );
}

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      style={{
        width: "20px",
        height: "20px",
        accentColor: "teal",
        cursor: "pointer",
      }}
      {...rest}
    />
  );
}
